import React, { useEffect, useState } from 'react';
import {Button} from '@common/ui/buttons/button';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const PwaInstallPrompt: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [showIosInstallGuide, setShowIosInstallGuide] = useState(false);

  // Check if the device is an iOS device
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent) && /safari/.test(userAgent) && !/crios|fxios/.test(userAgent);
  };

  // Check if the PWA is already installed (for iOS)
  const isInStandaloneMode = () => {
    return 'standalone' in window.navigator && (window.navigator as any).standalone;
  };

  useEffect(() => {
    // Handle the 'beforeinstallprompt' event for Android
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
      setDeferredPrompt(e); // Save the event for triggering later
      setIsInstallable(true); // Set flag to show install button
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);

    // Check for iOS installation state
    if (isIos() && !isInStandaloneMode()) {
      setShowIosInstallGuide(true); // Show iOS instructions
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
    };
  }, []);

  // Function to show install prompt on Android (Chrome)
  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the PWA install prompt');
      } else {
        console.log('User dismissed the PWA install prompt');
      }
      setDeferredPrompt(null); // Clear the prompt after usage
    }
  };

  return (
    <div>
      {/* Android Install Button */}
      {isInstallable && (
        <Button variant="outline" color="primary" onClick={handleInstallClick} style={{ display: 'block', marginBottom: '20px' }}>
          Install Web App
        </Button>
      )}

      {/* iOS Install Guide */}
      {showIosInstallGuide && (
        <div style={{ marginBottom: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '10px' }}>
          <p>
            To install this web app, tap <strong>Share</strong> and then <strong>Add to Home Screen</strong>.
          </p>
        </div>
      )}
    </div>
  );
};

export default PwaInstallPrompt;