import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {toast} from '@common/ui/toast/toast';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {User} from '@common/auth/user';
import {message} from '@common/i18n/message';
import {apiClient} from '@common/http/query-client';

interface Response extends BackendResponse {}

interface Payload {
  add_detail_1?: string;
  add_detail_2?: string;
  add_detail_3?: string;
  physical_detail_1?: string;
  physical_detail_2?: string;
  physical_detail_3?: string;
  physical_detail_4?: string;
  physical_detail_5?: string;
  physical_detail_6?: string;
  physical_detail_7?: string;
  physical_detail_8?: string;
  physical_detail_9?: string;
  health_detail_1?: string;
  health_detail_2?: string;
  health_detail_4?: string;
  digital_detail_1?: string;
  digital_detail_2?: string;
  digital_detail_3?: string;
  digital_detail_4?: string;
  digital_detail_5?: string;
  legal_detail_1?: string;
  legal_detail_2?: string;
  legal_detail_3?: string;
  legal_detail_4?: string;
  legal_detail_5?: string;
  legal_detail_6?: string;
  legal_detail_7?: string;
  financial_detail_1?: string;
  financial_detail_2?: string;
  financial_detail_3?: string;
  financial_detail_4?: string;
  funeral_detail_1?: string;
  funeral_detail_2?: string;
  funeral_detail_3?: string;
  funeral_detail_4?: string;
  funeral_detail_5?: string;
  funeral_detail_6?: string;
  funeral_detail_7?: string;
  funeral_detail_8?: string;
  funeral_detail_9?: string;
  funeral_detail_10?: string;
  funeral_detail_11?: string;
  funeral_detail_12?: string;
  funeral_detail_13?: string;
  funeral_detail_14?: string;
  funeral_detail_15?: string;
  funeral_detail_16?: string;
  funeral_detail_17?: string;
  funeral_detail_18?: string;
  funeral_detail_19?: string;
  funeral_detail_20?: string;
  anything_detail_1?: string;
  anything_detail_2?: string;
}

export function useUpdateWishes(form: UseFormReturn<Partial<User>>) {
  return useMutation({
    mutationFn: (props: Payload) => updateWishes(props),
    onSuccess: () => {
      toast(message('Updated account details'));
    },
    onError: r => onFormQueryError(r, form),
  });
}

function updateWishes(payload: Payload): Promise<Response> {
  return apiClient.put('users/me', payload).then(r => r.data);
}