import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {useUser} from '../use-user';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {BasicInfoPanel} from './basic-info-panel/basic-info-panel';
import {WishListPanel} from './wish-list-panel/wish-list-panel';
import {Trans} from '@common/i18n/trans';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {AccountSettingsPanel} from '@common/auth/ui/account-settings/account-settings-panel';
import {
  AccountSettingsId,
  AccountSettingsSidenav,
} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {SessionsPanel} from '@common/auth/ui/account-settings/sessions-panel/sessions-panel';
import {useContext} from 'react';
import {SiteConfigContext} from '@common/core/settings/site-config-context';

export function AccountWishesPage() {
  const {auth} = useContext(SiteConfigContext);
  const {data, isLoading} = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  return (
    <div className="min-h-screen bg-alt">
      <StaticPageTitle>
        <Trans message="Account Wishes" />
      </StaticPageTitle>
 	  <Navbar menuPosition="account-settings-page" />
	<div>
        <div className="container mx-auto px-24 py-24">
          <h1 className="text-3xl">
            <Trans message="WISHES" />
          </h1>
          <div className="mb-40 text-base text-muted">
            <Trans message="View and update your end of life wishes here." />
          </div>
          {isLoading || !data ? (
            <ProgressCircle
              className="my-80"
              aria-label="Loading Wishes.."
              isIndeterminate
            />
          ) : (
            <div className="flex items-start gap-24">
              <main className="flex-auto">
              
                <WishListPanel user={data.user} />
                
              </main>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
