import {Fragment, ReactNode} from 'react';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
}
export function TwoFactorStepperLayout({
  title,
  subtitle,
  description,
  actions,
  children,
}: Props) {
  if (!subtitle) {
    subtitle = (
      <>
			When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application. <Link to='https://www.google.com/search?q=google+authenticator+app+download&oq=google+au&gs_lcrp=EgZjaHJvbWUqDggAEEUYJxg7GIAEGIoFMg4IABBFGCcYOxiABBiKBTIGCAEQRRg5Mg4IAhBFGCcYOxiABBiKBTIKCAMQABixAxiABDIHCAQQABiABDIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPKgCALACAA&sourceid=chrome&ie=UTF-8'>here</Link>
	</>
    );
	  

  }
  return (
    <Fragment>
      <div className="text-base font-medium mb-16">{title}</div>
      <div className="text-sm mb-24">{subtitle}</div>
      <p className="text-sm font-medium my-16">{description}</p>
      {children}
      <div className="flex items-center gap-12">{actions}</div>
    </Fragment>
  );
}
